<template>
  <FocusTrap>
    <div class="card" id="myform">

<!--    <div class="card-header header-elements-inline bg-secondary" >-->
<!--      <h5 class="card-title">Designation Information</h5>-->
<!--      <div class="header-elements">-->
<!--        <div class="list-icons">-->
<!--          <a class="list-icons-item" data-action="reload"></a>-->
<!--          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
      <h5 v-if="designation.id == 0" class="card-title">Designation Creation</h5>
      <h5 v-if="designation.id > 0" class="card-title"> Designation : {{designation.id}}  Updation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>


    <div class="card-body" style="padding-top: 10px;">

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
        <div class="col-md-10">
          <input id="txtname" type="text" class="form-control" placeholder="Enter Name here..." maxlength="100" v-if="designation" v-model="designation.name"  autocomplete="off"  autofocus >
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Min Wage:</label>
        <div class="col-md-10">
          <input type="text" class="form-control" placeholder="Enter Minimum Wage..."  style="text-align: right" min="0" v-if="designation" v-model="designation.min_wage"   >
        </div>
      </div>


    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'DesignationForm',
    component: {

    },
    data(){
      return {
        designation: {"id":0,"status":"Active","type":0,"name":"","min_wage": "0.00"},
        designations: []
      }
    },
    props: {
      mydesignation: {
        type: Object,
        default: () =>  JSON.parse('{"id":0,"status":"Active","type":0,"name":"","min_wage":"0.00"}')
      }
    },
    beforeMount () {
      this.designation = this.mydesignation;
    },
    created(){

    },
    mounted(){
      $('#txtname').focus();
    },
    methods: {
      closeWindow() {
        this.$emit('designation_window_closed');
      },
      closeThis(){
        this.$emit('designation_window_closed');
        if(this.designation.id > 1){
          this.$modal.hide('designation-window');
          this.designation = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","min_wage":"0.00"}');
        }else{
          this.$router.push("/")
        }
      },
      saveDocument(){
        const self = this;

        self.$data.designation.min_wage = parseFloat(self.$data.designation.min_wage);

        if(self.$data.designation.name.trim().length < 1){
          alert("Invalid Name");
          return
        }

        const requestOptions = {
          method:  (self.$data.designation.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.designation)
        };

        $('#myform').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/hr/designation`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            swal ( { title: "Success!" ,  text: resp.msg, type:  "success" , onClose: () => { $('#txtname').focus()}, timer:1500} );
            self.designation = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","min_wage":"0.00"}');
            self.$emit('designation_saved', resp.data);
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error", timer:3000} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error", timer:3000} );
        }).finally(function () {
          $('#myform').unblock();
        });
      }
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
